<template>

  <div class="app">
    <div class="radio-box" v-for="(item,index) in radios" :key="item.id">
      <span class="radioStyle" :class="{'radioStyleChecked':item.isChecked}"  @click="huoqu(index)"></span>
      <input  v-model="sex" :value="item.value" class="input-radio" :checked='item.isChecked'  type="radio" style="display: none">{{item.label}}
    </div>
  </div>

  <van-radio-group
      style="height: 16vw"
      v-model="checked" direction="horizontal">
    <van-radio name="1">
      <template #icon="props">
      <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
    </template>
    </van-radio>
    <van-radio name="2">
      <template #icon="props">
        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
      </template>
    </van-radio>
  </van-radio-group>
</template>

<script>
export default {
  name: "Radio",
  data(){
    return {
      checked: '2',
      activeIcon:
          'https://fastly.jsdelivr.net/npm/@vant/assets/user-active.png',
      inactiveIcon:
          'https://fastly.jsdelivr.net/npm/@vant/assets/user-inactive.png',
        sex: '男',
        radios:[
          {
            label: '男',
            value:'男',
            isChecked: false,
          },
          {
            label: '女',
            value:'女',
            isChecked: false,
          }
        ]
    }
  },
  methods:{
    huoqu(index){
      // 先取消所有选中项
      this.radios.forEach((item) => {
        item.isChecked = false;
      });
      //再设置当前点击项选中
      this.sex = this.radios[index].value;
      // 设置值，以供传递
      this.radios[index].isChecked = true;
    }
  }
}
</script>

<style scoped>
.img-icon {
  height: 40px;
}
.radioStyle {
  display:inline-block;
  width: 24px;
  height: 24px;
  background: url("../../assets/img/访.png") 0 -3px no-repeat;background-size: 100%;
  margin-right: 2px;
}
.radioStyleChecked {
  background: url("../../assets/img/访.png") 0 0 no-repeat;
  background-size: 100%;
}
</style>